import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useUserStore } from '~/store/user';
import { ProviderType } from '~/model/UserInfo';
import { NavigationRegex } from '~/util/constants';

import styles from './UserNavProvider.module.scss';

type UserProfileNavigationProps = {
  onClickCallback: (showSidebar: boolean) => void;
};

export const UserNavProvider = observer(({ onClickCallback }: UserProfileNavigationProps) => {
  const router = useRouter();
  const user = useUserStore();

  const leadsMatch = NavigationRegex.LEADS.test(router.pathname);
  const jobsMatch = NavigationRegex.JOBS.test(router.pathname);
  const clinicianHomeMatch = NavigationRegex.CLINICIAN_HOME.test(router.pathname);

  let providerLinks = [
    {
      isActive: leadsMatch,
      href: `/users/${user.getUserId()}/leads`,
      label: 'Qualified leads',
    },
    {
      isActive: jobsMatch,
      href: `/users/${user.getUserId()}/jobs`,
      label: 'My jobs',
    },
  ];

  if (user.isProviderType(ProviderType.Clinician)) {
    providerLinks = [
      {
        isActive: clinicianHomeMatch,
        href: `/home/clinician`,
        label: 'My jobs',
      },
    ];
  }

  return (
    <>
      {providerLinks.map((entry) => (
        <li className={classNames(entry.isActive && styles.active)} key={entry.label} role="none">
          <Link onClick={() => onClickCallback(false)} href={entry.href}>
            {entry.label}
          </Link>
        </li>
      ))}
    </>
  );
});

UserNavProvider.displayName = 'UserNavProvider';
