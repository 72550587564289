import React, { useCallback } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { AuthStore } from '../authStore';
import { UserFields } from '~/util/fields/UserFields';
import { TextLikeField } from '~/types/Field';
import { CommonModal } from '../../../common/CommonModal';
import { CommonModalHeader } from '../../../common/CommonModalHeader';
import { SignInProviders } from '~/components/atoms/SignInProviders';
import { BaseForm } from '~/components/organisms/BaseForm';
import { InputField } from '~/components/atoms/InputField';
import { PasswordField } from '~/components/atoms/PasswordField';
import { CommonModalFooter } from '../../../common/CommonModalFooter';
import { LockIcon } from '~/images/LockIcon';
import useCustomForm from '~/hooks/useCustomForm';

import commonModalStyles from '../../../common/CommonModal.module.scss';
import styles from '../LoginModal.module.scss';

interface Props {
  auth: AuthStore;
}

export const EmailAuthStep: React.FC<React.PropsWithChildren<Props>> = observer(({ auth }) => {
  const userFields = new UserFields();

  const { fields, isLoading, handleChange, handleFormSubmit, actionAlert, formDirty } =
    useCustomForm({
      initialFields: Object.assign({}, ...userFields.loginFields.map((x) => ({ [x.name]: x }))),
      onSubmit: auth.formSubmit,
      onCancel: auth.onDismiss,
    });

  const maybeDismissModal = useCallback(() => {
    // only allow dismissing the modal if nothing is entered, and
    // we're on the login step
    if (!formDirty) auth.onDismiss();
  }, [auth, formDirty]);

  return (
    <CommonModal onDismiss={maybeDismissModal} className={styles.modal}>
      <CommonModalHeader title="Log In" showCloseButton={false} />
      <div className={classNames(commonModalStyles.modalMainContent, styles.login)}>
        <SignInProviders className={styles.providers} auth={auth} />
        <hr className={styles.or} />
        <BaseForm
          id="login"
          name="login"
          handleSubmit={handleFormSubmit}
          isLoading={isLoading}
          actionAlert={actionAlert}
        >
          <div className="field">
            <InputField
              onChange={handleChange}
              field={fields.email as TextLikeField}
              inputProps={{ autoComplete: 'username' }}
            />
          </div>

          {/* may be hidden but still works for password managers */}
          <div className={classNames('field', styles.hideField)}>
            <PasswordField
              field={fields.password as TextLikeField}
              onChange={handleChange}
              inputProps={{ autoComplete: 'current-password' }}
            />
          </div>
        </BaseForm>
      </div>
      <CommonModalFooter className={styles.loginFooter}>
        <button type="button" className="btn" onClick={auth.onDismiss}>
          Cancel
        </button>
        <button
          type="submit"
          form="login"
          className={classNames('btn red wide center lock', isLoading && 'loading')}
          data-testid="btn-continue"
          disabled={isLoading}
        >
          <LockIcon /> Continue
        </button>
      </CommonModalFooter>
    </CommonModal>
  );
});

EmailAuthStep.displayName = 'EmailAuthStep';
