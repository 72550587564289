/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import * as Base from '@radix-ui/react-radio-group';

import useCustomForm from '~/hooks/useCustomForm';
import { ProposalCreateFields } from '~/util/fields/ProposalCreateFields';
import { RadioSelectField, TextLikeField } from '~/types/Field';
import { InputField } from '~/components/atoms/InputField';
import { ApiResponse } from '~/util/ApiRequest';
import InputError from '~/components/atoms/InputError';
import type { Proposal } from '~/store/Proposal';

import styles from './ProposalPage.module.scss';
import stagePageStyles from './ProposalStagePage.module.scss';
import type { ActionAlert } from '~/types/ActionAlert';

type Props = {
  proposal: Proposal;
  onSubmit: (changedValues: Partial<Proposal>) => Promise<ApiResponse>;
  onFieldChange: () => void;
  submitFieldCallback: (callback: any) => void;
  setActionAlert: (actionAlert: ActionAlert | undefined) => void;
};

const allFields = ['projectStage', 'startDate'];

export const ProposalStagePage: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ proposal, onSubmit: callerSubmit, onFieldChange, submitFieldCallback, setActionAlert }) => {
    const createFields: ProposalCreateFields = new ProposalCreateFields(proposal);
    const initialFields = createFields.getFields(allFields);

    const { fields, handleChange, validateThenSubmitFields, actionAlert } = useCustomForm({
      initialFields,
      onSubmit: (values): Promise<ApiResponse> => {
        proposal.mergeForm(values);
        return callerSubmit(values);
      },
      onFieldChange,
    });

    const stageField = fields.projectStage as RadioSelectField;
    const onChangeChecked = (value: string) => {
      handleChange(stageField, value);
    };

    // pass function to call when this step is submitted
    submitFieldCallback(validateThenSubmitFields);
    useEffect(() => {
      setActionAlert(actionAlert);
    }, [actionAlert, setActionAlert]);

    return (
      <>
        <h3 className={styles.choose}>What stage in your home accessibility search are you in? </h3>

        <div>
          <Base.Root
            className={classNames('radio', stagePageStyles.projectStage)}
            onValueChange={onChangeChecked}
            id={stageField.id}
            name={stageField.name}
            value={stageField.value?.value}
          >
            {(() => {
              const option = stageField.options[0];
              return (
                <div className={stagePageStyles.stageOption}>
                  <Base.Item key={stageField.id} value={option.value} id={option.value}>
                    <Base.Indicator />
                  </Base.Item>
                  <label htmlFor={option.value}>
                    <img
                      src="/static/exploring.svg"
                      width="60"
                      height="48"
                      alt="exploring icon"
                      aria-hidden
                    />
                    <p className="text">Exploring Ideas</p>
                    <p>I am researching ideas for accessible home upgrades</p>
                  </label>
                </div>
              );
            })()}
            {(() => {
              const option = stageField.options[1];
              return (
                <div className={stagePageStyles.stageOption}>
                  <Base.Item key={stageField.id} value={option.value} id={option.value}>
                    <Base.Indicator />
                  </Base.Item>
                  <label htmlFor={option.value}>
                    <img
                      src="/static/budget.svg"
                      width="60"
                      height="48"
                      alt="budget icon"
                      aria-hidden
                    />
                    <p className="text">Budgeting</p>
                    <p>I have done research and I am budgeting for this modification</p>
                  </label>
                </div>
              );
            })()}
            {(() => {
              const option = stageField.options[2];
              return (
                <div className={stagePageStyles.stageOption}>
                  <Base.Item key={stageField.id} value={option.value} id={option.value}>
                    <Base.Indicator />
                  </Base.Item>
                  <label htmlFor={option.value}>
                    <img
                      src="/static/letsgo.svg"
                      width="60"
                      height="48"
                      alt="ready icon"
                      aria-hidden
                    />
                    <p className="text">Ready to go</p>
                    <p>I am ready to contract someone right now for this modification</p>
                  </label>
                </div>
              );
            })()}
            {(() => {
              const option = stageField.options[3];
              return (
                <div className={stagePageStyles.stageOption}>
                  <Base.Item key={stageField.id} value={option.value} id={option.value}>
                    <Base.Indicator />
                  </Base.Item>
                  <label htmlFor={option.value}>
                    <img
                      src="/static/construction.svg"
                      width="60"
                      height="48"
                      alt="construction icon"
                      aria-hidden
                    />
                    <p className="text">Project in progress</p>
                    <p>I need additional work completed for this modification</p>
                  </label>
                </div>
              );
            })()}
          </Base.Root>
          <InputError error={stageField.error} id={stageField.id} />
        </div>

        <div className={classNames(styles.standardField, stagePageStyles.dateField)}>
          <label htmlFor={fields.startDate.id}>
            <span>Requested Start Date:</span>
            <InputField field={fields.startDate as TextLikeField} onChange={handleChange} />
          </label>
        </div>
      </>
    );
  }
);
