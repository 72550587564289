import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import Link from 'next/link';

import { useUserStore } from '../../../store/user';

import { UserRole } from '../../../model/UserInfo';

import { ProfileAvatar } from '../../../pages/UserProfile/UserProfileAvatar';

import { AccountNav } from './AccountNav';
import { AuthSignupNav } from './AuthSignupNav';
import { BaseNav } from './BaseNav';
import { NavAlerts } from './NavAlerts';
import { UserNavClient } from './UserNavClient';
import { UserNavProvider } from './UserNavProvider';

import { KebabIcon } from '../../../images/KebabIcon';

import styles from './AppMainNav.module.scss';
import useOutsideClick from '../../../hooks/useOutsideClick';

export const AppMainNav = observer(function AppMainNav() {
  const userStore = useUserStore();
  const mainMenuRef = useRef<HTMLUListElement>(null);
  const joinNetworkRef = useRef<HTMLUListElement>(null);
  const [showMainMenu, setShowMainMenu] = useState(false);
  const [showJoinNetwork, setShowJoinNetwork] = useState(false);
  const user = userStore.getUserInfo();

  const onClickMainMenu = () => {
    setShowMainMenu(!showMainMenu);
    setShowJoinNetwork(false);
  };

  const onClickJoinNetwork = () => {
    setShowJoinNetwork(!showJoinNetwork);
    setShowMainMenu(false);
  };

  const closeDropdowns = useCallback(() => {
    const mainMenu = mainMenuRef.current;
    const joinNetwork = joinNetworkRef.current;
    if (mainMenu && mainMenu.classList.contains('show')) setShowMainMenu(false);
    if (joinNetwork && joinNetwork.classList.contains('show')) setShowJoinNetwork(false);
  }, []);

  const isOutsideDropdowns = useCallback(
    // This simply checks that the target is not a button or part of a button
    // This is to prevent the dropdown from closing when clicking on its controls
    (target: HTMLElement) => target.closest('button') === null,
    []
  );

  useOutsideClick(closeDropdowns, isOutsideDropdowns);

  function UserNav() {
    if (!user) {
      return null;
    }

    let nav: JSX.Element | null = null;
    if (user.role === UserRole.Client) {
      nav = <UserNavClient onClickCallback={setShowMainMenu} user={user} />;
    } else if (user.role === UserRole.Provider) {
      nav = <UserNavProvider onClickCallback={setShowMainMenu} />;
    }

    return (
      <>
        {nav}
        <AccountNav />
      </>
    );
  }

  return (
    <nav id="main-nav-container" role="navigation" aria-label="main" data-testid="main-nav">
      <ul
        className={classNames(
          'reset-list',
          styles.mainNav,
          !userStore.isAuthenticated() && styles.defaultMenu
        )}
      >
        <BaseNav showJoinNetwork={showJoinNetwork} onClickJoinNetwork={onClickJoinNetwork} />
        {userStore.isAuthenticated() && <NavAlerts />}
        <li className={userStore.isAuthenticated() ? styles.authProfileMenu : undefined}>
          <button
            aria-expanded={showMainMenu}
            aria-controls="main-nav"
            aria-haspopup="true"
            aria-label="site menu"
            className={classNames('button', styles.mainMenuToggle)}
            onClick={onClickMainMenu}
            type="button"
            id="main-nav-toggle"
            data-testid="hamburger-menu"
          >
            {userStore.isAuthenticated() && user ? (
              <ProfileAvatar className={styles.avatar} profile={user} />
            ) : (
              <KebabIcon label={showMainMenu ? 'Hide Menu' : 'Show Menu'} />
            )}
          </button>
        </li>
      </ul>
      {showJoinNetwork && (
        <ul
          id="join-options"
          ref={joinNetworkRef}
          role="menu"
          className={classNames('reset-list', 'elevated', styles.collapseMenu, {
            show: showJoinNetwork,
          })}
        >
          <li role="none">
            <Link role="menuitem" href="/contractor">
              Home modification contractors
            </Link>
          </li>
          <li role="none">
            <Link role="menuitem" href="/clinician">
              Home accessibility clinicians
            </Link>
          </li>
        </ul>
      )}
      {showMainMenu && (
        <ul
          aria-labelledby="main-nav-container"
          role="menu"
          id="main-nav"
          className={classNames('reset-list', 'elevated', styles.collapseMenu, {
            show: showMainMenu,
          })}
          ref={mainMenuRef}
        >
          {!userStore.isAuthenticated() && (
            <>
              <AuthSignupNav />
            </>
          )}

          <UserNav />

          <li className={styles.separator} role="none">
            <Link role="menuitem" href="/about">
              About Rosarium Health&trade;
            </Link>
          </li>
          <li role="none">
            <Link role="menuitem" href="/refer">
              Refer a client
            </Link>
          </li>
          <li role="none">
            <Link role="menuitem" href="/blog">
              Blog
            </Link>
          </li>
        </ul>
      )}
    </nav>
  );
});
