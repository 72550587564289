import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { Field, SelectField, TextLikeField } from '~/types/Field';
import { NameValueDict } from '~/types/FormTypes';
import { InputField } from '~/components/atoms/InputField';
import { StandardSelectFormField } from '../atoms/StandardSelectFormField';
import { AddressAutocompleteSuggestions } from '../molecules/AddressAutocompleteSuggestion';
import { AutocompletePrediction, useAutoCompleteAddress } from '~/hooks/useAutocompleteAddress';
import { reaction, runInAction } from 'mobx';

import styles from './AutoCompleteAddress.module.scss';

type AutoCompleteAddressProps = {
  fields: NameValueDict<Field>;
  handleChange: (field: Field, newValue: any) => boolean;
};

export const AutoCompleteAddress = observer(
  ({ fields, handleChange }: AutoCompleteAddressProps) => {
    const { data, getAddressComponents, setValue, status } = useAutoCompleteAddress();

    const selectingValue = useRef(false);
    useEffect(
      () =>
        reaction(
          () => fields.streetAddress.value,
          (value) => {
            if (selectingValue.current) {
              return;
            }
            // update the autocomplete value from the field as it changes
            setValue(value);
          }
        ),

      // do not need to rerun when value changes, autorun will do that
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [setValue]
    );

    const handleSelect = async (suggestion: AutocompletePrediction) => {
      const { selectedStreetAddress, selectedCity, selectedState, selectedZipCode } =
        await getAddressComponents(suggestion);

      selectingValue.current = true;
      runInAction(() => {
        handleChange(fields.streetAddress, selectedStreetAddress);
        // This will not be filled by the Places API, so we
        // need to clear the current value.
        handleChange(fields.aptSuiteNumber, '');
        handleChange(fields.city, selectedCity);
        handleChange(fields.state, selectedState);
        handleChange(fields.zipCode, selectedZipCode);
      });
      selectingValue.current = false;
    };

    return (
      <>
        <div className={classNames('field', styles.streetAddressField)}>
          <InputField onChange={handleChange} field={fields.streetAddress as TextLikeField} />
          {status === 'OK' && (
            <AddressAutocompleteSuggestions
              data={data}
              handleSelectedAddress={(prediction) => handleSelect(prediction)}
            />
          )}
        </div>
        <InputField onChange={handleChange} field={fields.aptSuiteNumber as TextLikeField} />

        <InputField onChange={handleChange} field={fields.city as TextLikeField} />

        <div className="field">
          <StandardSelectFormField
            label={<span className="input-label-text">{fields.state.label}</span>}
            field={fields.state as SelectField}
            onChangeForm={handleChange}
          />
        </div>

        <InputField onChange={handleChange} field={fields.zipCode as TextLikeField} />
      </>
    );
  }
);
