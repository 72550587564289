/* eslint-disable max-classes-per-file */
import { ISelectOption } from '../../model/helper';
import { DaySchedule, weekDayReadableString } from '../../model/UserInfo';
import { Field, GroupField, IFile, PendingUploadFile } from '../../types/Field';
import { NameValueDict } from '../../types/FormTypes';

const csvReducer = (previousValue: string, currentValue: string) =>
  previousValue ? `${previousValue}, ${currentValue}` : currentValue;

export interface FieldValidator<T> {
  validate: (value?: T, allFields?: NameValueDict<Field>) => string | undefined;
}

const getCapitalizedFieldName = (fieldName: string) =>
  fieldName.charAt(0).toUpperCase() + fieldName.slice(1);

export class TextFieldValidator implements FieldValidator<string | number> {
  constructor(
    private fieldName: string,
    private lengthRange?: { min?: number; max?: number },
    private regExp?: RegExp,
    private required: boolean = true
  ) {}

  public validate(value?: string | number) {
    if (typeof value !== 'string') return;

    const capitalizedFieldName = getCapitalizedFieldName(this.fieldName);
    if (this.required && (!value || value.length === 0))
      return `${capitalizedFieldName} cannot be empty`;

    if (this.lengthRange && this.lengthRange.max && value.length > this.lengthRange.max)
      return `${capitalizedFieldName} cannot contain more than ${this.lengthRange.max} characters`;
    if (this.lengthRange && this.lengthRange.min && value.length < this.lengthRange.min)
      return `${capitalizedFieldName} cannot contain less than ${this.lengthRange.min} characters`;

    if (this.regExp && !this.regExp.test(value)) return `Invalid ${this.fieldName} format`;
  }
}

export class DecimalNumberFieldValidator implements FieldValidator<string | number> {
  constructor(
    private fieldName: string,
    private range?: { min: number; max: number },
    private required = true
  ) {}

  public validate(value?: string | number) {
    const capitalizedFieldName = getCapitalizedFieldName(this.fieldName);
    if (value === undefined || value === null || value === '') {
      if (this.required) return `${capitalizedFieldName} cannot be empty`;
      return;
    }
    const numberValue = Number(value);
    if (Number.isNaN(numberValue)) {
      return `${capitalizedFieldName} must be a valid number`;
    }
    if (this.range && (numberValue < this.range.min || numberValue > this.range.max))
      return `${capitalizedFieldName} must be a number between ${this.range.min} and ${this.range.max}`;
  }
}

export class IntegerFieldValidator implements FieldValidator<string | number> {
  constructor(
    private fieldName: string,
    private range?: { min: number; max: number },
    private required = true
  ) {}

  // TODO: this function should probably not accept a string at all?
  // Let's decide during code review whether we want to make the proposed changes
  public validate(value?: string | number) {
    const capitalizedFieldName = getCapitalizedFieldName(this.fieldName);
    if (value === null || value === undefined || value === '') {
      if (this.required) return `${capitalizedFieldName} cannot be empty`;
      return;
    }

    const numberValue = Number(value);
    if (Number.isNaN(numberValue) || !Number.isInteger(numberValue)) {
      return `${capitalizedFieldName} must be an integer number`;
    }

    // TODO: if the function only accepts a number type, we would not need to do explicit conversion here
    if (this.range && (Number(value) < this.range.min || Number(value) > this.range.max))
      return `${capitalizedFieldName} must be an integer number between ${this.range.min} and ${this.range.max}`;
  }
}

export class DayScheduleValidator implements FieldValidator<DaySchedule[]> {
  public validate(value?: DaySchedule[]) {
    for (const daySchedule of value || []) {
      try {
        if (!daySchedule.isOpen) return;
        const startTimeMinutes = this.getMinutesFromTimeString(daySchedule.startTime);
        const endTimeMinutes = this.getMinutesFromTimeString(daySchedule.endTime);
        if (startTimeMinutes >= endTimeMinutes)
          return `Invalid time interval for ${
            weekDayReadableString[daySchedule.day]
          }. Start time has to be before end time.`;
      } catch {
        return `Invalid time interval for ${weekDayReadableString[daySchedule.day]}`;
      }
    }
  }

  private getMinutesFromTimeString(time: string) {
    const [hours, minutes] = time.split(':');
    const hoursNumber = Number.parseInt(hours, 10);
    const minutesNumber = Number.parseInt(minutes, 10);
    return hoursNumber * 60 + minutesNumber;
  }
}

export class FileFieldValidator implements FieldValidator<File> {
  constructor(private maxTotalSizeMb?: number) {}

  public validate(value?: File) {
    if (!this.maxTotalSizeMb) return;

    if (value && value.size > this.maxTotalSizeMb * 1024 * 1024)
      return `Exceeded ${this.maxTotalSizeMb} MB upload limit. Please make sure that the size of your selected uploads is <= ${this.maxTotalSizeMb} MB`;
  }
}

export class ImageFieldValidator implements FieldValidator<IFile[]> {
  constructor(private maxTotalSizeMb?: number) {}

  public validate(value?: IFile[]) {
    if (!this.maxTotalSizeMb) return;

    if (value) {
      let totalFileSize = 0;
      for (const file of value) {
        if (file instanceof PendingUploadFile) totalFileSize += file.fileData.size;
      }
      if (totalFileSize > this.maxTotalSizeMb * 1024 * 1024)
        return `Exceeded ${this.maxTotalSizeMb} MB upload limit. Please make sure that the size of your selected uploads is <= ${this.maxTotalSizeMb} MB`;
    }
  }
}

export class RepeatedFieldValidator implements FieldValidator<GroupField[]> {
  constructor(private required: boolean = true) {}

  public validate(value?: GroupField[]) {
    if (this.required && (!value || value.length === 0)) return 'You need to add at least one item';
  }
}

export class MultipleImageFieldValidator implements FieldValidator<IFile[]> {
  constructor(
    private entityNames: string,
    private maxNumberOfFiles: number,
    private maxImageSizeMb: number,
    private maxVideoSizeMb: number,
    private required: boolean = true
  ) {}

  public validate(value?: IFile[]) {
    if (!value || value.length === 0) {
      if (this.required) return `You need to select one or more ${this.entityNames}`;
      return;
    }
    const totalUploadedFiles = value?.length || 0;

    const errors = [];
    if (totalUploadedFiles > this.maxNumberOfFiles)
      errors.push(`You can select maximum ${this.maxNumberOfFiles} ${this.entityNames}`);

    const largeImages: string[] = [];
    const largeVideos: string[] = [];
    for (const file of value) {
      if (!(file instanceof PendingUploadFile)) continue;
      const { fileData } = file;
      if (fileData.type.startsWith('image') && fileData.size > this.maxImageSizeMb * 1024 * 1024)
        largeImages.push(fileData.name);
      else if (
        fileData.type.startsWith('video') &&
        fileData.size > this.maxVideoSizeMb * 1024 * 1024
      )
        largeVideos.push(fileData.name);
    }

    if (largeVideos.length > 0 || largeImages.length > 0) {
      if (largeImages.length > 0) {
        const imageList = largeImages.reduce(csvReducer, '');
        errors.push(
          `${imageList} exceed${largeImages.length === 1 ? 's' : ''} the ${
            this.maxImageSizeMb
          } MB photo size limit.`
        );
      }
      if (largeVideos.length > 0) {
        const videoList = largeVideos.reduce(csvReducer, '');
        errors.push(
          `${videoList} exceed${largeVideos.length === 1 ? 's' : ''} the ${
            this.maxVideoSizeMb
          } MB video size limit.`
        );
      }
    }
    if (errors.length) return errors.reduce(csvReducer, '');
  }
}

export class MultiSelectFieldValidator implements FieldValidator<ISelectOption[]> {
  constructor(
    private fieldName: string,
    private required = true
  ) {}

  public validate(value?: ISelectOption[]) {
    if (this.required && (!value || value.length === 0))
      return `You need to select at least one option for ${this.fieldName}`;
    if (value) {
      for (const option of value) {
        if (!option.label || !option.value) return 'Options cannot be empty';
      }
    }
  }
}

export class EnumFieldValidator implements FieldValidator<string[]> {
  private options: Set<string>;

  constructor(
    options: string[],
    private required: boolean,
    private fieldName: string
  ) {
    this.options = new Set(options);
  }

  public validate(value?: string[]) {
    if (!value || (value.length === 0 && this.required))
      return `You need to select at least one option for ${this.fieldName}`;

    const difference = value.filter((v) => !this.options.has(v));
    if (difference.length) {
      return `${this.fieldName}: some options are invalid: ${JSON.stringify(difference)}`;
    }
  }
}

export class RequiredSelectFieldValidator implements FieldValidator<string> {
  // TODO: I wonder whether the idea was that fieldName would be used in the error message?
  // new RequiredSelectFieldValidator('contact time of day') from:
  // src/util/fields/ProposalCreateFields.tsx
  constructor(private fieldName?: string) {}

  public validate(value?: string | boolean) {
    // The value could be a boolean. If so, we need to convert it to a string.
    if (typeof value === 'boolean') {
      value = value.toString();
    }

    if (!value || value.length === 0) return `Please select an option`;
  }
}

export class RadioSelectFieldValidator implements FieldValidator<ISelectOption> {
  constructor(private selectedEntity: string) {}

  public validate(value?: ISelectOption) {
    if (!value || !value.label || !value.value)
      return `You need to select a valid ${this.selectedEntity}`;
  }
}

export class DateFieldValidator implements FieldValidator<number | string> {
  constructor(
    private fieldName: string,
    private range?: { min?: string; max?: string },
    private outOfRangeMessage?: string
  ) {}

  public validate(value?: number | string) {
    if (!value) return `You need to select a ${this.fieldName}`;
    try {
      if (
        this.range &&
        ((this.range.min && value < this.range.min) || (this.range.max && value > this.range.max))
      ) {
        return this.outOfRangeMessage || `Invalid value for ${this.fieldName}`;
      }
    } catch (error) {
      return `You need to select a valid ${this.fieldName}`;
    }
  }
}

export class ListFieldValidator implements FieldValidator<string[]> {
  constructor(
    private fieldName: string,
    private required: boolean = true,
    private min?: number,
    private max?: number
  ) {}

  public validate(value?: string[]) {
    if (this.required && (!value || value.length === 0)) {
      return `Please select an option`;
    }
    if (this.min !== undefined && (!value || value.length < this.min)) {
      return `Expected at list ${this.min} options`;
    }
    if (this.max !== undefined && (!value || value.length >= this.max)) {
      return `Expected at most ${this.max} options`;
    }
  }
}

export class URLFieldValidator implements FieldValidator<string | number> {
  constructor(
    private fieldName: string,
    private required: boolean = true
  ) {}

  public validate(value?: string | number) {
    if (typeof value !== 'string') return;

    if (this.required) {
      if (!value || value.length === 0) {
        return `The link cannot be empty`;
      }
    } else if (!value || value.length === 0) {
      return;
    }

    try {
      // eslint-disable-next-line no-new
      const url = new URL(value);
      if (url.protocol !== 'http:' && url.protocol !== 'https:') {
        return 'The Link must use http or https';
      }
      if (url.hostname.split('.').length === 1) {
        return 'The link must contain a valid host name';
      }
    } catch (err) {
      return 'The link must contain a valid URL (e.g. https://www.example.com)';
    }
  }
}
