import { ForgotPasswordModal } from '../templates/modals/users/forgotPassword/ForgotPasswordModal';
import { CreateProposal } from '../../pages/CreateProposal';
import { ModalType, useSiteStore } from '../../store/site';
import { observer } from 'mobx-react-lite';
import { LoginModal } from '../templates/modals/users/login/LoginModal';
import { useUserStore } from '../../store/user';
import { UserRole } from '../../model/UserInfo';
import { useEffect, useState } from 'react';
import { MessageModal } from '../molecules/MessageModal';

export const CommonModals = observer(function CommonModals() {
  const site = useSiteStore();
  const user = useUserStore();
  const [warningShown, setWarningShown] = useState(false);

  const hideModal = () => site.showModal(false);

  const currentModal = site.modalShowing;
  const userRole = user.getUserRole();
  const isAuthenticated = user.isAuthenticated();
  useEffect(() => {
    if (isAuthenticated && !currentModal && userRole === UserRole.Pending) {
      if (!warningShown) {
        setTimeout(() => {
          site.setError(
            'Your registration is not yet complete. Please select an account type before continuing.',
            'Registration Error'
          );
          setWarningShown(true);
        });
      }
      console.warn('User is pending, showing modal');
      site.showModal(ModalType.Login);
    }
  }, [currentModal, isAuthenticated, site, user, userRole, warningShown]);

  return (
    <>
      {currentModal === ModalType.ForgotPassword && <ForgotPasswordModal />}
      {currentModal === ModalType.Login && <LoginModal onDismiss={hideModal} />}
      {currentModal === ModalType.Signup && <LoginModal onDismiss={hideModal} />}
      {currentModal === ModalType.CreateProposal && <CreateProposal {...site.modalProps} />}
      {currentModal === ModalType.Message && <MessageModal {...site.modalProps} />}
    </>
  );
});
