import { IUserStore } from '../../../../../store/user';
import { makeAutoObservable, runInAction } from 'mobx';
import {
  ApiResponse,
  ApiResponseStatus,
  ApiSuccess,
  postRequest,
} from '../../../../../util/ApiRequest';
import { ISiteStore, ModalType, siteStore } from '../../../../../store/site';
import { NameValueDict } from '../../../../../types/FormTypes';
import { FieldValueType } from '../../../../../types/Field';
import { MouseEvent } from 'react';
import { IUserInfo, UserRole } from '../../../../../model/UserInfo';
import { waitFor } from '../../../../../util/waitFor';

export enum SSOProviders {
  Google = 'google',
}

export enum AuthMethod {
  Sso = 'sso',
  Email = 'email',
}

export enum AuthStep {
  // first screen
  Email = 'email',
  // showing the password field plus any available provider buttons
  Login = 'password',
  // waiting for social auth
  Social = 'social',
  // after sign up activate account
  Activation = 'activation',
  // final profile data
  Profile = 'profile',
}

const FlowSteps: Record<AuthMethod, AuthStep[]> = {
  [AuthMethod.Email]: [AuthStep.Email, AuthStep.Login],
  [AuthMethod.Sso]: [AuthStep.Email],
};

export type AuthMode = 'email' | SSOProviders.Google;

const SUCCESS: ApiSuccess = {
  status: ApiResponseStatus.Success,
  data: {},
};

export class AuthStore {
  public currentStep = AuthStep.Email;

  public authMethod: AuthMethod | null = null;

  public suggestedModes: Set<AuthMode> = new Set([]);

  private readonly callerOnLogin?: () => void;

  private readonly callerOnDismiss: () => void;

  public userInfo: Partial<IUserInfo> = {};

  constructor(
    private user: IUserStore,
    private site: ISiteStore,
    onDismiss: () => void,
    onLogin?: () => void
  ) {
    makeAutoObservable(this, {});
    this.callerOnDismiss = onDismiss;
    this.callerOnLogin = onLogin;
  }

  onDismiss = () => {
    this.callerOnDismiss();
  };

  onLogin = () => {
    if (this.callerOnLogin) this.callerOnLogin();
    else this.onDismiss();
  };

  showForgot = (e?: Event | MouseEvent<HTMLElement>) => {
    if (e) e.preventDefault();
    // noinspection JSIgnoredPromiseFromCall
    this.site.showModal(ModalType.ForgotPassword);
  };

  get authSteps() {
    if (this.authMethod === null) {
      throw new Error('invalid auth method');
    }
    return FlowSteps[this.authMethod];
  }

  completedSso = async () => {
    await this.user.refresh();
    runInAction(() => {
      this.authMethod = AuthMethod.Sso;
      // in case we are on a different step that's no longer valid (password), go back to email
      this.currentStep = AuthStep.Email;
    });
    setTimeout(() => {
      this.completedStep();
    }, 1);
  };

  completedStep = () => {
    const steps = this.authSteps;
    const pos = steps.indexOf(this.currentStep);
    if (pos < 0) throw new Error(`Invalid step ${this.currentStep} for ${this.authMethod}`);
    if (pos + 1 >= steps.length) {
      // done
      return this.onLogin();
    }
    this.currentStep = steps[pos + 1];
  };

  goBack = () => {
    const steps = this.authSteps;
    const pos = steps.indexOf(this.currentStep);
    if (pos < 0) throw new Error(`Invalid step ${this.currentStep} for ${this.authMethod}`);
    if (pos - 1 < 0) {
      throw new Error(`can't go back from step ${this.currentStep}`);
    }
    this.currentStep = steps[pos - 1];
  };

  private updateSuggestedAuthModes = async (email: string, tries = 0): Promise<void> => {
    if (tries > 10) {
      console.error('failed to get suggested modes!');
      throw new Error('Unable to get suggested modes from the server');
    }

    const response = await postRequest('/users/suggest-auth-modes', { email });

    if (response.status === ApiResponseStatus.Error) {
      siteStore.setErrorTitle('Error');
      siteStore.setError('Unknown Error');
      // try again
      await waitFor(() => this.updateSuggestedAuthModes(email, tries + 1), 1000);
    }
    this.suggestedModes = new Set(response.data as AuthMode[]);
  };

  formSubmit = async (values: NameValueDict<FieldValueType>): Promise<ApiResponse> => {
    switch (this.currentStep) {
      case AuthStep.Email: {
        const { email } = values;
        if (!email || typeof email !== 'string' || !email.includes('@')) {
          return {
            status: ApiResponseStatus.Error,
            data: 'Invalid email',
          };
        }
        await this.updateSuggestedAuthModes(email);
        this.authMethod = AuthMethod.Email;

        this.userInfo.email = email;
        this.completedStep();
        return SUCCESS;
      }
      case AuthStep.Login: {
        const response = await this.user.login(values);
        if (response.status !== ApiResponseStatus.Error) {
          this.completedStep();
        }
        return response;
      }
      case AuthStep.Activation: {
        const response = await this.user.verify(values.code as string);
        if (response.status !== ApiResponseStatus.Error) {
          this.completedStep();
        }
        return response;
      }
      case AuthStep.Profile: {
        // this one has multiple steps and will call completeStep() when done
        return this.user.update(values);
      }
      default:
        return {
          status: ApiResponseStatus.Error,
          data: 'Internal error: invalid step for form',
        };
    }
  };
}
