import { NameValueDict } from '~/types/FormTypes';
import { UploadedFile } from '~/types/Field';
import { ISelectOption } from './helper';
import { IAddress, IContact, IProviderInfo } from './UserInfo';

export enum RequestType {
  MarketplaceJobRequest = 'MARKETPLACE_JOB_REQUEST',
  PayerPaidContractorJobRequest = 'PAYER_PAID_CONTRACTOR_JOB_REQUEST',
  PayerPaidClinicianVisit = 'PAYER_PAID_CLINICIAN_VISIT',
}

// This tracks the status of the proposal as a whole
export enum ProposalStatus {
  Published = 'PUBLISHED',
  Connected = 'CONNECTED',
  InProgress = 'IN_PROGRESS',
  ClinicianUnscheduled = 'CLINICIAN_UNSCHEDULED',
  ClinicianScheduled = 'CLINICIAN_SCHEDULED',
  ProviderComplete = 'PROVIDER_COMPLETED',
  Verified = 'VERIFIED',
  Canceled = 'CANCELED',

  // Legacy
  // client side only (for now)
  Draft = 'DRAFT',
}

export const proposalStatusReadableString = {
  [ProposalStatus.Published]: 'Looking for Providers',
  [ProposalStatus.Connected]: 'Looking for Providers',
  [ProposalStatus.InProgress]: 'In progress',
  [ProposalStatus.ClinicianUnscheduled]: 'Assessment not scheduled',
  [ProposalStatus.ClinicianScheduled]: 'Assessment scheduled',
  [ProposalStatus.ProviderComplete]: 'Provider marked the job as complete',
  [ProposalStatus.Verified]: 'Complete',
  [ProposalStatus.Canceled]: 'Canceled',
  [ProposalStatus.Draft]: 'Draft',
};

export enum ProposalProviderStatus {
  New = 'new',
  Connected = 'connected',
  Declined = 'declined',
  Canceled = 'canceled',
  Inactive = 'inactive',
  InProgress = 'in_progress',
  Completed = 'completed',
  Verified = 'verified',
  Lost = 'lost',
  Unavailable = 'unavailable', // Catch-all for when other statuses are not applicable
}

export enum ClinicianJobStatus {
  ClinicianUnscheduled = 'clinician_unscheduled',
  ClinicianScheduled = 'clinician_scheduled',
  AssessmentPending = 'assessment_pending',
  AssessmentInReview = 'assessment_in_review',
  Verified = 'verified',
}

export const proposalProviderStatusReadableString = {
  [ProposalProviderStatus.New]: 'Accepting Connections',
  [ProposalProviderStatus.Connected]: 'Connected',
  [ProposalProviderStatus.Declined]: 'Declined',
  [ProposalProviderStatus.Canceled]: 'Canceled',
  [ProposalProviderStatus.Inactive]: 'Inactive',
  [ProposalProviderStatus.InProgress]: 'In Progress',
  [ProposalProviderStatus.Completed]: 'Completed',
  [ProposalProviderStatus.Verified]: 'Verified', // Clinician status may also be 'Verified'
  [ProposalProviderStatus.Lost]: 'Accepted another bid',
  [ProposalProviderStatus.Unavailable]: 'Unavailable',
  [ClinicianJobStatus.ClinicianUnscheduled]: 'Unscheduled',
  [ClinicianJobStatus.ClinicianScheduled]: 'Scheduled',
  [ClinicianJobStatus.AssessmentPending]: 'Assessment Pending',
  [ClinicianJobStatus.AssessmentInReview]: 'Assessment In Review',
};

// This tracks the status of an individual provider
export enum ProposalConnectionStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Converted = 'CONVERTED',
  Lost = 'LOST',
}

export enum LeadProviderStatus {
  New = 'new',
  Connected = 'connected',
  Declined = 'declined',
  Unavailable = 'unavailable',
}

export enum JobProviderStatus {
  InProgress = 'in_progress',
  Completed = 'completed',
}

export enum JobRequestPersonalizedLeadsStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Declined = 'Declined',
}

export const proposalConnectionStatusReadableString = {
  [ProposalConnectionStatus.Pending]: 'Pending',
  [ProposalConnectionStatus.Accepted]: 'Accepted',
  [ProposalConnectionStatus.Declined]: 'Declined',
  [ProposalConnectionStatus.Converted]: 'Converted',
  [ProposalConnectionStatus.Lost]: 'Lost',
};

export enum ProposalOrigin {
  Personalized = 'PERSONALIZED',
  Provider = 'PROVIDER',
  Invite = 'INVITE',
}

export enum ProposalType {
  Invite = 'INVITE',
  Public = 'PUBLIC',
}

export enum ContactTimeOfDayOptions {
  Morning = 'morning_7am_12pm',
  Afternoon = 'afternoon_12pm_5pm',
  Evening = 'evening_5pm_8pm',
  Anytime = 'anytime_7am_8pm',
}

export const contactTimeOfDayReadableString = {
  [ContactTimeOfDayOptions.Morning]: 'Morning (7AM - 12PM)',
  [ContactTimeOfDayOptions.Afternoon]: 'Afternoon (12PM - 5PM)',
  [ContactTimeOfDayOptions.Evening]: 'Evening (5PM - 8PM)',
  [ContactTimeOfDayOptions.Anytime]: 'Anytime (7AM - 8PM)',
};

export interface IConnection {
  additionalDeclineInfo?: string;
  connectionStatus: ProposalConnectionStatus;
  bidId?: string;
  declineReasons?: string[];
  origin: ProposalOrigin;
  providerId: string;
  provider?: IProviderInfo;
  proposalId: string;
}

export enum RequesterType {
  Myself = 'MYSELF',
  Spouse = 'SPOUSE',
  Parent = 'PARENT',
  Child = 'CHILD',
  Sibling = 'SIBLING',
  Grandparent = 'GRANDPARENT',
  Grandchild = 'GRANDCHILD',
  OtherFamily = 'OTHER_FAMILY',
  Friend = 'FRIEND',
  LegalGuardian = 'LEGAL_GUARDIAN',
  PowerOfAttorney = 'POWER_OF_ATTORNEY',
  Professional = 'PROFESSIONAL',
  HealthCareProvider = 'HEALTH_CARE_PROVIDER',
  Other = 'OTHER',
  Patient = 'PATIENT',
}

export const RequesterTypeLabel = {
  [RequesterType.Myself]: 'Self',
  [RequesterType.Spouse]: 'Spouse',
  [RequesterType.Parent]: 'Parent',
  [RequesterType.Child]: 'Child',
  [RequesterType.Sibling]: 'Sibling',
  [RequesterType.Grandparent]: 'Grandparent',
  [RequesterType.Grandchild]: 'Grandchild',
  [RequesterType.OtherFamily]: 'Other Family Member',
  [RequesterType.Friend]: 'Friend/Neighbor',
  [RequesterType.LegalGuardian]: 'Legal Guardian',
  [RequesterType.PowerOfAttorney]: 'Power of Attorney',
  [RequesterType.Professional]: 'Professional Caregiver',
  [RequesterType.HealthCareProvider]: 'Health Care Provider',
  [RequesterType.Other]: 'Other',
};

export enum RequesterHealthRole {
  Planner = 'HOSPITAL_DISCHARGE_PLANNER',
  HomeHealthRep = 'HOME_HELP_REP',
}

export enum PreviousModifications {
  Yes = 'YES',
  No = 'NO',
  Unknown = 'UNKNOWN',
}

export enum PlannedPaymentOption {
  OutOfPocket = 'OUT_OF_POCKET',
  Insurance = 'INSURANCE',
  WorkersComp = 'WORKERS_COMP',
  Unsure = 'UNSURE',
}

const PlannedPaymentLabel = {
  [PlannedPaymentOption.OutOfPocket]: 'Out of Pocket',
  [PlannedPaymentOption.Insurance]: 'Private Insurance',
  [PlannedPaymentOption.WorkersComp]: "Worker's Compensation",
  [PlannedPaymentOption.Unsure]: "I don't know",
};

export function getPlannedPaymentLabel(s?: PlannedPaymentOption): string {
  if (!s) return '';
  return PlannedPaymentLabel[s];
}

export interface IProposal {
  id?: string;
  requestType?: RequestType;
  client?: Partial<IContact>;
  contact?: Partial<IContact>;
  requesterType?: RequesterType;
  requesterHealthRole?: RequesterHealthRole;
  type: ProposalType;
  status?: ProposalStatus;
  providerStatus?: ProposalProviderStatus | ClinicianJobStatus;
  connections?: IConnection[];
  providerConnection?: IConnection;
  config?: NameValueDict<string | boolean>;
  services?: ISelectOption[];
  address?: IAddress;
  projectStage?: ISelectOption;
  projectId?: string;
  dateCreated?: Date | string;
  startDate?: string; // YYYY-mm-dd string
  rentOrOwn?: string;
  hasSharedWalls?: string;
  ageOfHome?: number;
  previousModifications?: PreviousModifications;
  isUsingInsuranceFunds?: boolean;
  plannedPaymentOptions?: PlannedPaymentOption[];
  isNeededBeforeMedicalDischarge?: boolean;
  isSupportingFunctionalDecline?: boolean;
  primaryContactId?: string;
  visuals?: UploadedFile[];
  supportingDocuments?: UploadedFile[];
  communicationMethod?: ISelectOption[];
  contactTimeOfDay?: ContactTimeOfDayOptions;
  additionalInformation?: string;
  savedByProviders?: string[];
  declineReasons?: string[];
  additionalDeclineInfo?: string;
  createdAt?: string | Date;
  permissions?: string[];
}

export interface IClinicianJobRequest extends IProposal {
  id: string;
  distance?: number;
  earnGross?: string;
  earnFeePercent?: string;
  earnFeeAmount?: string;
  earnAmount?: string;
  mapUrl?: string;
}

export interface IClinicianJob extends IClinicianJobRequest {
  dateStarted: string;
  dateOfAssessment: string;
}

export type ProposalFormKey = keyof IProposal;
