import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { FieldValueType } from '../../../../../types/Field';
import { NameValueDict } from '../../../../../types/FormTypes';
import { ApiResponse, ApiResponseStatus } from '../../../../../util/ApiRequest';

import usePagination from '../../../../../hooks/usePagination';

import { CREATE_JOB_REQUEST_BUTTON_LABELS } from '../../../../../util/constants';
import { localStorage } from '../../../../../util/localStorage';

import { ProposalStartPage } from './ProposalStartPage';
import { ProposalModalHeader } from './ProposalModalHeader';
import { ProposalAddressPage } from './ProposalAddressPage';
import { ProposalStagePage } from './ProposalStagePage';
import { ProposalUploadPage } from './ProposalUploadPage';
import { Proposal } from '../../../../../store/Proposal';
import { IProviderInfo } from '../../../../../model/UserInfo';

import { BaseForm } from '../../../../organisms/BaseForm';
import { Notecard } from '../../../../molecules/Notecard';
import { StepNavigation } from './StepNavigation';
import { ProposalModalFooter } from './ProposalModalFooter';

import { CommonModal } from '../../common/CommonModal';
import { CommonModalFooter } from '../../common/CommonModalFooter';
import { RightArrowIcon } from '../../../../../images/RightArrowIcon';
import commonModalStyles from '../../common/CommonModal.module.scss';
import { useSiteStore } from '../../../../../store/site';
import { IProposal } from '~/model/Proposal';
import type { ActionAlert } from '~/types/ActionAlert';
import { FormAlert } from '~/components/molecules/FormAlert';

interface Props {
  /**
   * Handler for when the operation is cancelled or the modal is closed
   */
  onDismiss: () => void;
  /**
   * Handler for when user hits the send proposal button
   */
  onSave: (values: NameValueDict<FieldValueType>) => Promise<ApiResponse>;

  editPage?: number;

  /**
   * the proposal to edit, if editing
   */
  proposal: Proposal;

  /**
   * in case there is an invited provider
   */
  provider?: IProviderInfo;
}

export const ProposalModal: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ onDismiss, onSave, editPage, proposal, provider }) => {
    const site = useSiteStore();
    const TIP_KEY = 'proposal-header-dismiss-tip';
    const modalTitle = proposal.isEdit ? 'Edit Job Request' : 'Create Job Request';

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [showTip, setShowTip] = useState<boolean>(
      parseInt(localStorage.getItem(TIP_KEY) || '', 10) !== 1 &&
        // don't show on edit
        !proposal.isEdit
    );
    const [actionAlert, setActionAlert] = useState<ActionAlert>();

    const onHideTip = () => {
      localStorage.setItem(TIP_KEY, '1');
      setShowTip(false);
    };
    const { currentPage, changePage } = usePagination(6, editPage);
    const hasBack = currentPage > 0;

    const handleDismiss = () => {
      // If in edit mode and nothing was changed, simply close the modal
      if (proposal.isEdit && !isDirty) {
        onDismiss();
        return;
      }

      site
        .confirm({
          message: (
            <>
              <p className="text-center">
                {proposal.isEdit ? (
                  <>Are you sure you want to exit the job request editor?</>
                ) : (
                  <>Are you sure you want to exit job request creation?</>
                )}
                <br /> <small>If you exit, the data entered will be lost.</small>
              </p>
            </>
          ),
          okLabel: 'Exit',
        })
        .then(() => onDismiss())
        .catch(() => {});
    };

    const handleBack = () => {
      if (proposal.isEdit) {
        handleDismiss();
        return;
      }
      if (currentPage > 0) changePage(currentPage - 1);
    };

    const handleNext = async (changedValues: Partial<Proposal>) => {
      if (proposal.isEdit || currentPage === 3) {
        return onSave(changedValues);
      }
      changePage(currentPage + 1);
      const response: ApiResponse = await Promise.resolve({
        data: null,
        status: ApiResponseStatus.Success,
      });
      return response;
    };

    // it got tedious
    const win: any = globalThis;
    win.setProposalPage = changePage;

    let content;
    let nextButton: React.ReactNode;
    let beforeSubmitCallback: () => void;
    let submitFieldsCallback: () => Promise<ApiResponse>;
    switch (currentPage) {
      case 0:
        content = (
          <ProposalStartPage
            proposal={proposal}
            onSubmit={handleNext}
            onFieldChange={() => setIsDirty(true)}
            provider={provider}
            submitFieldCallback={(callback) => {
              submitFieldsCallback = callback;
            }}
            setActionAlert={setActionAlert}
          />
        );
        nextButton = (
          <>
            {`${CREATE_JOB_REQUEST_BUTTON_LABELS.SELECT_STAGE}`}
            <RightArrowIcon />
          </>
        );
        break;
      case 1:
        content = (
          <ProposalStagePage
            proposal={proposal}
            onSubmit={handleNext}
            onFieldChange={() => setIsDirty(true)}
            submitFieldCallback={(callback) => {
              submitFieldsCallback = callback;
            }}
            setActionAlert={setActionAlert}
          />
        );
        nextButton = (
          <>
            {`${CREATE_JOB_REQUEST_BUTTON_LABELS.UPLOAD_MEDIA}`}
            <RightArrowIcon />
          </>
        );
        break;
      case 2:
        content = (
          <ProposalUploadPage
            proposal={proposal}
            onSubmit={handleNext}
            onFieldChange={() => setIsDirty(true)}
            submitFieldCallback={(callback) => {
              submitFieldsCallback = callback;
            }}
            setActionAlert={setActionAlert}
          />
        );
        nextButton = (
          <>
            {`${CREATE_JOB_REQUEST_BUTTON_LABELS.ADDRESS_INFO}`}
            <RightArrowIcon />
          </>
        );
        break;
      case 3:
        content = (
          <ProposalAddressPage
            beforeSubmitCallback={(callback) => {
              beforeSubmitCallback = callback;
            }}
            proposal={proposal}
            onSubmit={handleNext}
            onFieldChange={() => setIsDirty(true)}
            submitFieldCallback={(callback) => {
              submitFieldsCallback = callback;
            }}
            setActionAlert={setActionAlert}
          />
        );
        nextButton = (
          <>
            {`${CREATE_JOB_REQUEST_BUTTON_LABELS.SUBMIT_REQUEST}`}
            <RightArrowIcon />
          </>
        );
        break;
      default:
        throw new Error('unexpected page');
    }

    function formSubmit(event: React.FormEvent<HTMLFormElement>) {
      event.preventDefault();

      // Some steps have a custom function that should be called before
      // calling `validateThenSubmitFields` from `useCustomForm`
      if (beforeSubmitCallback) {
        beforeSubmitCallback();
      }

      if (submitFieldsCallback) {
        setIsLoading(true);
        submitFieldsCallback().then(() => {
          setIsLoading(false);
        });
      }
    }

    return (
      <>
        <CommonModal onDismiss={handleDismiss}>
          <ProposalModalHeader modalTitle={modalTitle} />
          {!proposal.isEdit && <StepNavigation current={currentPage} count={4} />}
          {showTip && (
            <Notecard closeButtonLabel="Close note" onDismiss={onHideTip}>
              <h3 className="notecard-title">How the job request process works</h3>
              <p>
                Enter in your home modification and our Rosarium Health&trade; pros will contact
                you. Rosarium Health&trade; makes it easy to find the contractor that can help you
                with your accessibility home modification needs.
              </p>
              {provider && currentPage === 0 && (
                <p>
                  You are also directly inviting <strong>{provider.companyName}</strong> to work
                  with you on this job.
                </p>
              )}
            </Notecard>
          )}
          <BaseForm name="proposal" handleSubmit={formSubmit as any} isLoading={isLoading}>
            <div className={commonModalStyles.modalMainContent}>{content}</div>
            {/* Embed a FormAlert here instead of in BaseForm so it shows up more legibly */}
            <FormAlert alert={actionAlert} />
            <CommonModalFooter>
              <ProposalModalFooter
                next={proposal.isEdit ? 'Submit' : nextButton}
                isLoading={isLoading}
                hasBack={hasBack}
                onBack={handleBack}
                editing={proposal.isEdit}
              />
            </CommonModalFooter>
          </BaseForm>
        </CommonModal>
      </>
    );
  }
);

ProposalModal.displayName = 'ProposalModal';
