import React, { useCallback, useEffect, useState } from 'react';
import type { ActionAlert } from '~/types/ActionAlert';
import { ActionAlertType } from '~/types/ActionAlert';
import { Field, FieldValueType, PendingUploadFile, RadioSelectField } from '~/types/Field';
import { NameValueDict } from '~/types/FormTypes';
import { ApiResponse, ApiResponseStatus, ServerValidationError } from '~/util/ApiRequest';
import { ISelectOption } from '~/model/helper';
import { action, runInAction } from 'mobx';
import { isInteger, parseNumber } from '~/util/numeric';
import { type IAddress, IAddressKeys } from '~/model/UserInfo';

type CustomFormParams<TFields> = {
  initialFields: TFields | (() => TFields);
  onSubmit: (values: NameValueDict<FieldValueType>) => Promise<ApiResponse>;
  successMessage?: string;
  successCallback?: (response: ApiResponse) => void;
  onCancel?: () => void;
  onFieldChange?: () => void;
  submitConfirmation?: () => Promise<boolean>;
};

export type CustomFormReturnValue<TFields> = {
  actionAlert: ActionAlert | undefined;
  assignNewFields: (newFields: TFields) => void;
  fields: TFields;
  handleChange: (field: Field, newValue: any) => boolean;
  handleFormSubmit: (event: React.FormEvent<HTMLFormElement> | undefined) => Promise<void>;
  isLoading: boolean;
  getSubmitFieldValues: () => Promise<ApiResponse>;
  validateThenSubmitFields: () => Promise<ApiResponse>;
  handleErrorResponseFromSubmit: (response: ApiResponse) => void;
  resetForm: () => void;
  setActionAlert: React.Dispatch<React.SetStateAction<ActionAlert | undefined>>;
  validateFields: (validatedFieldNames?: string[] | null, runValidations?: boolean) => boolean;
  formDirty: boolean;
  setFormDirty: (value: boolean) => void;
};

const useCustomForm = <TFields extends NameValueDict<Field> = NameValueDict<Field>>({
  initialFields,
  onSubmit,
  successMessage,
  successCallback,
  onCancel,
  onFieldChange,
  submitConfirmation,
}: CustomFormParams<TFields>): CustomFormReturnValue<TFields> => {
  // if initialFields is a function, call it to get the initial fields
  const [fields, setFields] = useState(
    (typeof initialFields === 'function' ? initialFields() : initialFields) || {}
  );
  const [fieldNames] = useState(Object.keys(fields || {}));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actionAlert, setActionAlert] = useState<ActionAlert>();
  const [formDirty, setFormDirty] = useState(false);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && onCancel) {
        onCancel();
      } else if (e.key) {
        setFormDirty(true);
      }
    };
    window.addEventListener('keyup', handler);
    return () => {
      window.removeEventListener('keyup', handler);
    };
  }, [onCancel]);

  const validateFields = useCallback(
    (validatedFieldNames: string[] | null = null, runValidations: boolean = true) => {
      let hasErrors = false;

      if (!validatedFieldNames) validatedFieldNames = fieldNames;

      validatedFieldNames.forEach((fieldName) => {
        const field = fields[fieldName];
        if (runValidations) {
          field.validate(fields);
        }
        if (field.error) hasErrors = true;
      });
      return hasErrors;
    },
    [fields, fieldNames]
  );

  const assignNewFields = (newFields: TFields) => {
    setFields(newFields);
  };

  /**
   * Return false if there are validation errors, true otherwise
   */
  const handleChange = action((field: Field, newValue: any) => {
    setActionAlert(undefined);

    // if it's a radio field, the value is an ISelect
    if (field instanceof RadioSelectField) {
      const option: ISelectOption = {
        value: newValue,
        label: 'invalid',
      };
      for (let i = 0; i < field.options.length; i++) {
        const o = field.options[i];
        if (o.value === newValue) {
          option.label = o.label;
        }
      }
      field.value = option;
    } else {
      field.value = newValue;
    }

    onFieldChange?.();

    if (field.validate) {
      field.validate(fields);
    }

    return !field.error;
  });

  const resetForm = () => {
    setIsLoading(false);
  };

  const resetAllFields = () => {
    Object.values(fields).forEach((f) => {
      const val = f.value as unknown;
      if (val instanceof PendingUploadFile) {
        val.close();
      }
    });
    for (const field of Object.values(fields)) {
      field.reset();
    }
  };

  const handleErrorResponseFromSubmit = useCallback(
    (response: ApiResponse) => {
      // If there is an error with the fields' values, we display that under its respective field.
      if (
        response.status === ApiResponseStatus.Error &&
        response.payload?.detail instanceof Array
      ) {
        const validationError: ServerValidationError = response.payload;
        let fieldErrorMatched = false;
        runInAction(() => {
          validationError.detail.forEach((error) => {
            let fieldName = error.loc.pop();
            let index: number | undefined;

            if (fieldName && isInteger(fieldName)) {
              index = parseNumber(fieldName);
              fieldName = error.loc.pop();
            }
            if (!fieldName) return;
            // In case the error appeared for a sub-field, show it on the field
            if (fieldName.indexOf('[') !== -1)
              fieldName = fieldName.substring(0, fieldName.indexOf('['));
            const field = fields[fieldName];
            if (field) {
              field.setError(error.msg, index);
              fieldErrorMatched = true;
            }
          });
        });
        if (!fieldErrorMatched) {
          setActionAlert({
            text: 'Some of the fields contain errors. Please check your selection and retry',
            type: ActionAlertType.Danger,
          });
        }
      } else if (response.status === ApiResponseStatus.Error) {
        // TODO: Refactor the code so that data can be of type object and remove cast to any.
        const errorData = response.data as any;
        setActionAlert({
          text:
            errorData.detail ||
            errorData.details ||
            (typeof errorData === 'string' ? errorData : JSON.stringify(errorData)),
          type: ActionAlertType.Danger,
        });
      }
    },
    [fields]
  );

  const getSubmitFieldValues = useCallback(async (): Promise<ApiResponse> => {
    const values: NameValueDict<FieldValueType> = {};
    const addressData: IAddress = {};

    for (let i = 0; i < fieldNames.length; i++) {
      const fieldName = fieldNames[i];
      const field = fields[fieldName];
      try {
        // eslint-disable-next-line no-await-in-loop
        const value = await field.getValueOnSubmit();
        if (
          Array.isArray(value) &&
          value.length > 0 &&
          value.every((v: unknown) => typeof v === 'object' && (v as any).objectKey === 'uploaded')
        ) {
          // never submit 'uploaded' values
          // this is an artifact of the file field setup..
          continue;
        }
        // Address field data needs to go into a nested address object
        if (fieldName in IAddressKeys) {
          addressData[fieldName as keyof IAddress] = value;
        } else {
          values[fieldName] = value;
        }
      } catch (error) {
        return {
          status: ApiResponseStatus.Error,
          data: 'Some of the fields contain errors. Please check your selection and retry.',
        };
      }
    }
    if (Object.keys(addressData).length > 0) {
      values.address = addressData;
    }
    return {
      status: ApiResponseStatus.Success,
      data: values,
    };
  }, [fields, fieldNames]);

  const onSubmitFields = async (): Promise<ApiResponse> => {
    setIsLoading(true);
    setActionAlert(undefined);
    const { status, data } = await getSubmitFieldValues();
    if (status === ApiResponseStatus.Error) {
      // Do not submit the form if there are errors getting the values of the fields
      setIsLoading(false);
      return { status, data };
    }

    let response;
    try {
      response = await onSubmit(data);
    } catch (e: any) {
      response = { status: ApiResponseStatus.Error, data: e.message };
    }
    setIsLoading(false);

    if (response.status === ApiResponseStatus.Error) {
      handleErrorResponseFromSubmit(response);
    } else {
      resetAllFields();
      if (successMessage) {
        setActionAlert({ text: successMessage, type: ActionAlertType.Success });
      }
      if (successCallback) {
        successCallback(response);
      }
    }
    return response;
  };

  const doFieldValidation = async (): Promise<ApiResponse> => {
    if (validateFields()) {
      setActionAlert({
        text: 'Some of the fields contain errors. Please check your selection and retry',
        type: ActionAlertType.Danger,
      });
      return { status: ApiResponseStatus.Error, data: 'Validation errors' };
    }
    return { status: ApiResponseStatus.Success, data: 'No validation errors' };
  };

  const validateThenSubmitFields = async (): Promise<ApiResponse> => {
    const validationResponse = await doFieldValidation();
    if (validationResponse.status === ApiResponseStatus.Error) return validationResponse;

    return onSubmitFields();
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement> | undefined) => {
    if (event) event.preventDefault();
    if (submitConfirmation) {
      const validationResponse = await doFieldValidation();
      if (validationResponse.status === ApiResponseStatus.Success) {
        const confirmed = await submitConfirmation();
        if (confirmed) await onSubmitFields();
      }
    } else {
      await validateThenSubmitFields();
    }
  };

  return {
    fields,
    assignNewFields,
    validateFields,
    actionAlert,
    setActionAlert,
    isLoading,
    handleChange,
    handleFormSubmit,
    getSubmitFieldValues,
    validateThenSubmitFields,
    handleErrorResponseFromSubmit,
    resetForm,
    formDirty,
    setFormDirty,
  };
};

export default useCustomForm;
