import React, { useContext } from 'react';

import { action, makeAutoObservable } from 'mobx';

import {
  ClinicianJobStatus,
  ContactTimeOfDayOptions,
  IConnection,
  IProposal,
  PlannedPaymentOption,
  PreviousModifications,
  ProposalConnectionStatus,
  ProposalOrigin,
  ProposalProviderStatus,
  ProposalStatus,
  ProposalType,
  RequesterHealthRole,
  RequesterType,
} from '~/model/Proposal';
import type { ISelectOption } from '~/model/helper';
import type { IAddress, IContact, IProviderInfo } from '~/model/UserInfo';

import { FieldValueType, IFile, UploadedFile } from '~/types/Field';
import type { NameValueDict } from '~/types/FormTypes';

import {
  ApiResponse,
  ApiResponseStatus,
  getRequest,
  patchRequest,
  postFormData,
  postRequest,
} from '~/util/ApiRequest';
import { AnalyticsEventLabels } from '~/util/constants';
import { ModificationServiceMap, ModificationServicesValues } from '~/util/ServiceConstants';

import { analytics } from './analytics';

export class Proposal implements IProposal {
  [key: string]: any;

  type: ProposalType = ProposalType.Public;

  additionalDeclineInfo: string | undefined;

  additionalInformation: string | undefined;

  ageOfHome: number | undefined;

  communicationMethod: ISelectOption[] | undefined;

  connections: IConnection[] | undefined;

  config: NameValueDict<string | boolean> | undefined;

  contactTimeOfDay: ContactTimeOfDayOptions | undefined;

  createdAt: Date | string | undefined;

  declineReasons: string[] | undefined;

  hasSharedWalls: string | undefined;

  id: string | undefined;

  isNeededBeforeMedicalDischarge: boolean | undefined;

  isSupportingFunctionalDecline: boolean | undefined;

  isUsingInsuranceFunds: boolean | undefined;

  loading: boolean = false;

  primaryContactId?: string;

  plannedPaymentOptions: PlannedPaymentOption[] | undefined;

  previousModifications: PreviousModifications | undefined;

  projectStage: ISelectOption | undefined;

  projectId: string | undefined;

  rentOrOwn: string | undefined;

  contact: Partial<IContact> | undefined;

  requesterHealthRole: RequesterHealthRole | undefined;

  requesterType: RequesterType | undefined;

  savedByProviders: string[] | undefined;

  services: ISelectOption[] | undefined;

  startDate: string | undefined; // YYYY-mm-dd string

  status: ProposalStatus | undefined;

  providerStatus: ProposalProviderStatus | ClinicianJobStatus | undefined;

  address: Partial<IAddress> = {};

  supportingDocuments: UploadedFile[] | undefined;

  visuals: UploadedFile[] | undefined;

  actions: string[] | undefined;

  permissions: string[] | undefined;

  rosariumAssessmentId: string | undefined;

  visitAppointmentTime: string | undefined;

  distance: number | undefined;

  earnGross: string | undefined;

  earnFeePercent: string | undefined;

  earnFeeAmount: string | undefined;

  earnAmount: string | undefined;

  mapUrl: string | undefined;

  client: IContact | undefined;

  constructor(initial?: Partial<Proposal>) {
    if (initial) this.mergeForm(initial);

    makeAutoObservable(this, {
      create: action.bound,
      patch: action.bound,
      acceptProposal: action.bound,
      declineProposal: action.bound,
    });
  }

  load(id: string): Promise<ApiResponse> {
    return getRequest(`/proposals/${id}`).then(
      action((response) => {
        if (response.status === ApiResponseStatus.Success) {
          this.mergeForm(response.data);
        }
        return response;
      })
    );
  }

  create = (): Promise<ApiResponse> =>
    postRequest('/proposals', this).then(
      action((response) => {
        if (response.status === ApiResponseStatus.Success) {
          this.mergeForm(response.data);
        }
        // noinspection JSIgnoredPromiseFromCall
        globalThis.Rose.site.loadAlerts();
        return response;
      })
    );

  patch(values: NameValueDict<FieldValueType>): Promise<{ data: any; status: ApiResponseStatus }> {
    return patchRequest(`/proposals/${this.id}`, {
      ...values,
      type: this.type,
    }).then(
      action((response) => {
        if (response.status === ApiResponseStatus.Success) {
          this.mergeForm(response.data);
        }
        return response;
      })
    );
  }

  async acceptProposal(values?: NameValueDict<FieldValueType>): Promise<ApiResponse> {
    values = values || {};
    values.connectionStatus = ProposalConnectionStatus.Accepted;
    const response = await postRequest(`/proposals/${this.id}/connect`, values);
    if (response.status === ApiResponseStatus.Success) {
      this.connections = response.data.connections;
      this.mergeForm(response.data);
      analytics.event('proposal_connected', this);
    }
    return response;
  }

  /**
   * Decline a job request.
   * NOTE: When declining a job request the provider found through search or
   * personalised leads, there will not be a connection on the job request.
   * In these cases, call the function without a connection.
   * @param connection - optional connection to mark as declined.
   * @param analyticsEventLabel - optional label for analytics event [default='proposal_declined']
   */
  declineProposal(
    connection: Partial<IConnection>,
    analyticsEventLabel: string = AnalyticsEventLabels.JOB_REQUEST_DECLINED
  ): Promise<{ data: any; status: ApiResponseStatus }> {
    this.loading = true;
    const promise = postRequest(`/proposals/${this.id}/invite/decline`, connection);
    promise
      .then(
        action((response) => {
          this.loading = false;
          if (response.status === ApiResponseStatus.Success) {
            analytics.event(analyticsEventLabel, this);
            this.load(this.id!);
            return response;
          }
        })
      )
      .catch((e: any) => {
        this.loading = false;
        Rose.site.errorTitle = 'Error';
        Rose.site.errorMessage = e.message || 'Error while declining';
      });
    return promise;
  }

  /**
   * Providers can mark a job request as complete
   */
  markComplete(): Promise<ApiResponse> {
    return postRequest(`/proposals/${this.id}/mark-complete`, {}).then(
      action((response) => {
        if (response.status === ApiResponseStatus.Success) {
          this.mergeForm(response.data);
        }
        return response;
      })
    );
  }

  /**
   * Providers can certify that a job request has been completed
   */
  verifyClientComplete(): Promise<ApiResponse> {
    return postRequest(`/job-request/${this.id}/workflow/verify`, {}).then(
      action((response) => {
        if (response.status === ApiResponseStatus.Success) {
          this.mergeForm(response.data);
        }
        return response;
      })
    );
  }

  cancelRequest(): Promise<ApiResponse> {
    return postRequest(`/job-request/${this.id}/workflow/cancel`, {}).then(
      action((response) => {
        if (response.status === ApiResponseStatus.Success) {
          this.mergeForm(response.data);
        }
        return response;
      })
    );
  }

  getProviderConnection(providerId: string): IConnection | null {
    if (!this.connections) return null;
    for (let i = 0; i < this.connections.length; i++) {
      const conn = this.connections[i];
      if (conn.providerId === providerId) return conn;
    }
    return null;
  }

  /**
   * Get the connection status if it exists for the provider.
   * @param providerId - the provider id
   * @param excludeInvited - exclude connections with a ProposalOrigin of Invite
   * @returns the connection status or null if it doesn't exist
   */
  getProviderConnectionStatus(
    providerId: string,
    excludeInvited: boolean = false
  ): ProposalConnectionStatus | null {
    const connection = this.getProviderConnection(providerId);

    if (connection && !excludeInvited) {
      return connection.connectionStatus;
    }

    if (connection && excludeInvited) {
      return connection.origin !== ProposalOrigin.Invite ? connection.connectionStatus : null;
    }

    return null;
  }

  isProviderInvited(userId?: string | false | null): boolean {
    if (!userId) return false;
    return this.invitesSent.some(
      (connection) =>
        connection.providerId === userId && connection.origin === ProposalOrigin.Invite
    );
  }

  isProviderConnected(userId?: string | false | null): boolean {
    if (!userId) return false;
    const conn = this.getProviderConnection(userId);
    return Boolean(conn?.connectionStatus === ProposalConnectionStatus.Accepted);
  }

  /**
   * Computed property for checking that there has been a provider selected for the job
   */
  get isJob(): boolean {
    let isJobCheck = false;

    if (
      this.providerStatus === ProposalProviderStatus.InProgress ||
      this.providerStatus === ProposalProviderStatus.Completed
    ) {
      isJobCheck = true;
    }

    if (this.status)
      isJobCheck = [
        ProposalStatus.InProgress,
        ProposalStatus.ProviderComplete,
        ProposalStatus.Verified,
      ].includes(this.status);

    return isJobCheck;
  }

  /**
   * Computed property for checking that the proposal is in edit mode
   */
  get isEdit(): boolean {
    return this.status !== ProposalStatus.Draft;
  }

  /**
   * Computed property for checking that there are fees subtracted from the gross amount
   */
  get hasFeesForClinician(): boolean {
    try {
      const feeValue = (this.earnFeeAmount || '0').replace(/\$/g, '');
      const numericFee = parseFloat(feeValue);
      return numericFee > 0;
    } catch (e) {
      // If unable to parse, default to true so we show fees
      return true;
    }
  }

  /**
   * computed property for efficient access to connections for invitations
   */
  get invitesSent(): IConnection[] {
    return this.connections?.filter((conn) => conn.origin === ProposalOrigin.Invite) || [];
  }

  /**
   * computed property for efficient access to connections for connections (make by a provider)
   */
  get connectionsReceived(): IConnection[] {
    return (
      this.connections?.filter(
        (conn) => conn.connectionStatus === ProposalConnectionStatus.Accepted
      ) || []
    );
  }

  /**
   * Computed property that returns true if the job request accepts bid submissions
   */
  get acceptsBids(): boolean {
    return Boolean(this.config && this.config.bids);
  }

  get submittedBid(): string | undefined {
    const connectionWithBid = this.connections?.find((conn) => !!conn.bidId);
    return connectionWithBid?.bidId;
  }

  get hiredProviderConnection(): IConnection | null {
    const hiredProviders =
      this.connections?.filter(
        (conn) => conn.connectionStatus === ProposalConnectionStatus.Converted
      ) || [];
    if (hiredProviders.length === 0) return null;
    return hiredProviders[0];
  }

  async toggleProviderInvite(provider: IProviderInfo): Promise<ApiResponse> {
    const isInvited = this.isProviderInvited(provider.id);
    return isInvited ? this.uninviteProvider(provider) : this.inviteProvider(provider);
  }

  async inviteProvider(provider: IProviderInfo): Promise<ApiResponse> {
    const newConnection = {
      origin: ProposalOrigin.Invite,
      providerId: provider.id as string,
      proposalId: this.id as string,
    };
    const response = await postRequest(`/proposals/${this.id}/invite`, newConnection);

    if (response.status !== ApiResponseStatus.Error) {
      const responseData = response.data;
      this.connections = responseData.connections;
    } else {
      Rose.site.error = response.data || 'Unknown error';
      Rose.site.errorTitle = 'Error';
    }

    return response;
  }

  async uninviteProvider(provider: IProviderInfo): Promise<ApiResponse> {
    const response = await postRequest(`/proposals/${this.id}/invite/remove`, {
      providerId: provider.id as string,
      proposalId: this.id as string,
    });

    if (response.status !== ApiResponseStatus.Error) {
      const responseData = response.data;
      this.connections = responseData.connections;
    } else {
      Rose.site.error = response.data || 'Unknown error';
      Rose.site.errorTitle = 'Error';
    }

    const responseData = response.data;
    this.connections = responseData.connections;
    return response;
  }

  async hireProvider(providerId: string): Promise<ApiResponse> {
    const response = await postRequest(`/job-request/${this.id}/workflow/select_provider`, {
      providerId,
    });

    if (response.status !== ApiResponseStatus.Error) {
      const responseData = response.data;
      this.connections = responseData.connections;
    } else {
      Rose.site.error = response.data || 'Unknown error';
      Rose.site.errorTitle = 'Failed to hire selected provider';
    }

    this.mergeForm(response.data);
    return response;
  }

  async scheduleClinicianAssessment(assessmentTime: string): Promise<ApiResponse> {
    const response = await postRequest(`/job-request/${this.id}/workflow/schedule_visit`, {
      visitAppointmentTime: assessmentTime,
    });
    if (response.status === ApiResponseStatus.Success) this.mergeForm(response.data);
    return response;
  }

  async rescheduleClinicianAssessment(assessmentTime: string): Promise<ApiResponse> {
    const response = await postRequest(`/job-request/${this.id}/workflow/reschedule_visit`, {
      visitAppointmentTime: assessmentTime,
    });
    if (response.status === ApiResponseStatus.Success) this.mergeForm(response.data);
    return response;
  }

  async uploadOfflineAssessment(
    values: NameValueDict<FieldValueType>,
    confirmed: boolean
  ): Promise<ApiResponse> {
    const formData = new FormData();
    if (values.file instanceof File) formData.append('file', values.file);
    const response = await postFormData(
      `/form/for-job-request/${this.id}/pdf/${confirmed ? '?confirm=1' : ''}`,
      formData
    );
    if (response.status === ApiResponseStatus.Success && this.id) this.load(this.id);
    return response;
  }

  static isAssessmentSelected(services: ISelectOption[]): boolean {
    return !!services.find(
      (element) => element.value === ModificationServicesValues.AccessibilitySafetyAssessment
    );
  }

  mergeForm(values: NameValueDict<FieldValueType> | Proposal) {
    // Instead of Object.assign(this, values), we only select the fields that are defined on the
    // store
    Object.keys(values).forEach((key) => {
      if (key in this) {
        this[key] = values[key];
      }
    });

    if (Proposal.isAssessmentSelected(this.services || [])) {
      this.services = [
        ModificationServiceMap[ModificationServicesValues.AccessibilitySafetyAssessment],
      ];
    }

    ['visuals', 'supportingDocuments'].forEach((n) => {
      if (this[n]) {
        const files = this[n];
        for (let i = 0; i < files.length; i++) {
          if (!(files[i] instanceof IFile)) {
            const data = files[i] as any;
            files[i] = new UploadedFile(data.objectKey, data.url, data.description);
          }
        }
      }
    });
  }

  hasPermission(permission: string): boolean {
    return (this.permissions || []).includes(permission);
  }

  static downloadOfflineAssessment = () => {
    window.open('/api/form/def-latest/rosarium-assessment/pdf/', '_blank');
  };
}

export const ProposalContext = React.createContext<Proposal | null | undefined>(null);

export const useProposal = () => useContext(ProposalContext);
