/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import useCustomForm from '~/hooks/useCustomForm';
import { ProposalCreateFields } from '~/util/fields/ProposalCreateFields';
import { ImageField, PendingUploadFile } from '~/types/Field';
import { ApiResponse } from '~/util/ApiRequest';
import { ImageUploadField } from '~/components/atoms/ImageUploadField';
import { UploadFilesImagePreview } from '~/components/atoms/UploadFilesImagePreview';
import InputError from '~/components/atoms/InputError';
import type { Proposal } from '~/store/Proposal';

import styles from './ProposalPage.module.scss';
import uploadPageStyles from './ProposalUploadPage.module.scss';
import type { ActionAlert } from '~/types/ActionAlert';

type Props = {
  proposal: Proposal;
  onSubmit: (changedValues: Partial<Proposal>) => Promise<ApiResponse>;
  onFieldChange: () => void;
  submitFieldCallback: (callback: any) => void;
  setActionAlert: (actionAlert: ActionAlert | undefined) => void;
};

const allFields = ['visuals', 'supportingDocuments'];

export const ProposalUploadPage: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ proposal, onSubmit: callerSubmit, onFieldChange, submitFieldCallback, setActionAlert }) => {
    const createFields: ProposalCreateFields = new ProposalCreateFields(proposal);
    const initialFields = createFields.getFields(allFields);

    const { fields, handleChange, validateThenSubmitFields, actionAlert } = useCustomForm({
      initialFields,
      onSubmit: (values): Promise<ApiResponse> => {
        proposal.mergeForm(values);
        return callerSubmit(values);
      },
      onFieldChange,
    });

    const visualField = fields.visuals as ImageField;
    const documentsField = fields.supportingDocuments as ImageField;

    const onFileRemove = (field: ImageField, key: string) => {
      let files = field.value;
      if (!files) return;
      files = files.filter((file) => {
        if (file.objectKey === key && file instanceof PendingUploadFile) {
          file.close();
        }
        return file.objectKey !== key;
      });
      handleChange(field, files);
    };

    const onFileDescriptionChange = (field: ImageField, key: string, newDescription: string) => {
      let files = field.value;
      if (!files) return;
      files = files.map((file) => {
        if (file.objectKey === key) {
          file.description = newDescription;
        }
        return file;
      });
      handleChange(field, files);
    };

    // pass function to call when this step is submitted
    submitFieldCallback(validateThenSubmitFields);
    useEffect(() => {
      setActionAlert(actionAlert);
    }, [actionAlert, setActionAlert]);

    return (
      <>
        <h3 className={styles.choose}>Upload photos, videos, or supporting documentation</h3>
        <p>
          Do not upload any files which may contain personally identifiable information or that may
          violate HIPAA
        </p>
        <hr />

        <div className={styles.standardField}>
          <label htmlFor={visualField.id}>
            Upload photos and videos to give providers some visual insight of the spaces you need
            modified.
            <p>
              <small>
                Tip: Take a picture of each corner of the room. Panoramic photos work well.
              </small>
            </p>
            <ImageUploadField
              buttonContainerAlignment="left"
              field={visualField}
              onChange={handleChange}
              className={uploadPageStyles.visuals}
              preview={false}
              extendedLabel={
                <small className={uploadPageStyles.footnote}>
                  JPG, GIF, PNG, MOV, or MP4. Max size of 5MB
                </small>
              }
            />
          </label>

          {visualField.value && visualField.value.length > 0 && (
            <UploadFilesImagePreview
              files={visualField.value || []}
              onRemoveFile={(key) => onFileRemove(visualField, key)}
              onFileDescriptionChange={(key, newDescription) =>
                onFileDescriptionChange(visualField, key, newDescription)
              }
            />
          )}
          <InputError error={visualField.error} />
        </div>

        <div className={styles.standardField}>
          <label htmlFor={documentsField.id}>
            <span>
              Upload supporting documentation such as modification suggestions given to you by
              medical personnel
            </span>
            <small>(e.g. physical therapist, occupational therapist, physician, etc.)</small>
            <ImageUploadField
              buttonContainerAlignment="left"
              field={documentsField}
              onChange={handleChange}
              className={uploadPageStyles.visuals}
              preview={false}
              extendedLabel={<small className={uploadPageStyles.footnote}>Max size of 5MB</small>}
            />
          </label>

          {documentsField.value && documentsField.value.length > 0 && (
            <UploadFilesImagePreview
              files={documentsField.value || []}
              onRemoveFile={(key) => onFileRemove(documentsField, key)}
              onFileDescriptionChange={(key, newDescription) =>
                onFileDescriptionChange(documentsField, key, newDescription)
              }
            />
          )}
          <InputError error={documentsField.error} />
        </div>
      </>
    );
  }
);
