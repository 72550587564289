import classNames from 'classnames';

import Link from 'next/link';
import { LinkedInLogoIcon, InstagramLogoIcon, EnvelopeClosedIcon } from '@radix-ui/react-icons';
import { FacebookLogoIcon } from '../../images/FacebookLogoIcon';
import { PhoneOutlineIcon } from '~/images/PhoneOutlineIcon';
import { SUPPORT_EMAIL, SUPPORT_PHONE } from '~/util/constants';

import styles from './Footer.module.scss';

export const Footer = () => {
  const phoneURI = SUPPORT_PHONE.replace(/\s/g, '');
  return (
    <footer className={styles.pageFooter} data-testid="page-footer">
      <div className={styles.pageFooterSectionGroup}>
        <div className={styles.pageFooterSection}>
          <ul aria-label="Services" className={classNames('reset-list', styles.pageFooterList)}>
            <li>
              <Link href="/refer">Refer a client</Link>
            </li>
          </ul>
        </div>
        <div className={styles.pageFooterSection}>
          <ul
            aria-label="Join our provider network"
            className={classNames('reset-list', styles.pageFooterList)}
          >
            <li>
              <Link href="/clinician">Home accessibility clinicians</Link>
            </li>
            <li>
              <Link href="/contractor">Home modification contractors</Link>
            </li>
          </ul>
        </div>
        <div className={styles.pageFooterSection}>
          <ul aria-label="Company" className={classNames('reset-list', styles.pageFooterList)}>
            <li>
              <Link href="/about-rose">About Rosarium Health&trade;</Link>
            </li>
            <li>
              <Link href="/blog">Blog</Link>
            </li>
          </ul>
        </div>
        <div className={styles.pageFooterSection}>
          <ul aria-label="Contact us" className={classNames('reset-list', styles.pageFooterList)}>
            <li>
              <Link href={`tel:${phoneURI}`} className={styles.pageFooterContactInfo}>
                <PhoneOutlineIcon width="19" height="19" /> {SUPPORT_PHONE}
              </Link>
            </li>
            <li>
              <Link href={`mailto:${SUPPORT_EMAIL}`} className={styles.pageFooterContactInfo}>
                <EnvelopeClosedIcon width="19" height="19" /> {SUPPORT_EMAIL}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <ul className={classNames('reset-list', styles.pageFooterSocialLinkGroup)}>
        <li>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <a
            href="https://www.facebook.com/rosariumhealth"
            target="_blank"
            rel="noreferrer"
            className={styles.pageFooterSocialLink}
          >
            <FacebookLogoIcon hasBackground={false} />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/rosariumhealth"
            target="_blank"
            rel="noreferrer"
            className={styles.pageFooterSocialLink}
          >
            <LinkedInLogoIcon aria-label="LinkedIn" />
          </a>
        </li>
        <li>
          <a
            href="https://instagram.com/rosarium_health"
            target="_blank"
            rel="noreferrer"
            className={styles.pageFooterSocialLink}
          >
            <InstagramLogoIcon aria-label="Instagram" />
          </a>
        </li>
      </ul>
      <ul className={classNames('reset-list', styles.pageFooterBottomLinks)}>
        <li>
          <a
            aria-label="Provide feedback (opens in new window)"
            href="https://forms.gle/ZU8RYgHoFv2n4h8S7"
            rel="noopener noreferrer"
            target="_blank"
          >
            Provide Feedback
          </a>
        </li>
      </ul>
    </footer>
  );
};
