import styles from './Logo.module.scss';
import React from 'react';
import Link from 'next/link';
import Image from 'next/legacy/image';

export function Logo(): React.ReactElement {
  return (
    <Link className={styles.headerLogo} href="/">
      <Image
        src="/static/rose-logo.png"
        width="146"
        height="40"
        className="d-inline-block align-top"
        alt="Rosarium Health"
      />
    </Link>
  );
}
