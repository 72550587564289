import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { ArrowLeftIcon } from '@radix-ui/react-icons';
import { AuthStore } from '../authStore';
import { UserFields } from '~/util/fields/UserFields';
import { TextLikeField } from '~/types/Field';
import { CommonModal } from '../../../common/CommonModal';
import { SignInProviders } from '~/components/atoms/SignInProviders';
import { BaseForm } from '~/components/organisms/BaseForm';
import { InputField } from '~/components/atoms/InputField';
import { PasswordField } from '~/components/atoms/PasswordField';
import { CommonModalFooter } from '../../../common/CommonModalFooter';
import { LockIcon } from '~/images/LockIcon';
import useCustomForm from '~/hooks/useCustomForm';
import { AuthStepTitle } from '../AuthStepTitle';

import styles from '../LoginModal.module.scss';
import commonModalStyles from '../../../common/CommonModal.module.scss';

interface Props {
  auth: AuthStore;
}

export const LoginAuthStep: React.FC<React.PropsWithChildren<Props>> = observer(({ auth }) => {
  const userFields = new UserFields();
  const ssoModes = new Set(auth.suggestedModes);
  ssoModes.delete('email');

  const { fields, isLoading, handleChange, handleFormSubmit, actionAlert } = useCustomForm({
    initialFields: Object.assign(
      {},
      ...userFields.loginFields.map((x) => {
        if (x.name === 'email') {
          x.value = auth.userInfo.email;
        }
        return { [x.name]: x };
      })
    ),
    onSubmit: auth.formSubmit,
    onCancel: auth.onDismiss,
  });

  return (
    <CommonModal className={styles.modal}>
      <AuthStepTitle auth={auth} label="Log In" />
      <div className={classNames(commonModalStyles.modalMainContent, styles.login)}>
        <SignInProviders className={styles.providers} authModes={auth.suggestedModes} auth={auth} />

        {ssoModes.size > 0 && <hr className={styles.or} />}

        <BaseForm
          id="login"
          name="login"
          handleSubmit={handleFormSubmit}
          isLoading={isLoading}
          actionAlert={actionAlert}
        >
          <InputField
            onChange={handleChange}
            field={fields.email as TextLikeField}
            inputProps={{ autoComplete: 'username' }}
          />

          <PasswordField
            field={fields.password as TextLikeField}
            onChange={handleChange}
            inputProps={{ autoComplete: 'current-password' }}
          />
          <p>
            Not you?{' '}
            <button type="button" className="btn link" onClick={auth.goBack}>
              Use another account
            </button>
          </p>
          <p>
            Forgot your password?{' '}
            <a href="/forgot-password" onClick={auth.showForgot}>
              Click here
            </a>
          </p>
        </BaseForm>
      </div>
      <CommonModalFooter className={styles.loginFooter}>
        <button type="button" className="btn" onClick={auth.onDismiss}>
          Cancel
        </button>
        <div className={styles.loginFooterButtonGroup}>
          <button type="button" className="btn secondary" onClick={auth.goBack}>
            <ArrowLeftIcon width={20} height={20} /> Back
          </button>
          <button
            type="submit"
            form="login"
            className={classNames('btn red wide center lock', isLoading && 'loading')}
            data-testid="btn-continue"
            disabled={isLoading}
          >
            <LockIcon /> Log In
          </button>
        </div>
      </CommonModalFooter>
    </CommonModal>
  );
});

LoginAuthStep.displayName = 'LoginAuthStep';
